<template>
  <div>
    <el-row>
      <!-- icon="el-icon-circle-plus-outline"  @click="edit('add', '')-->
      <el-button v-throttle type="primary" @click="dialogVisibleBtn('add')"
        >新建租户</el-button
      >
    </el-row>
    <el-row style="margin-top: 20px">
      <el-form :inline="true" :model="conditionForm">
        <el-form-item label="租户编码/名称">
          <el-input
            v-model.trim="conditionForm.query"
            placeholder="输入用户编码或名称"
            @change="changeConditionForm"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="conditionForm.tenantStatus"
            @change="changeConditionForm"
            clearable
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in conditionList.tenantStatus"
              :key="item"
              :label="item | tenantStatusFilters"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button v-throttle type="primary" @click="changeConditionForm"
            >查询</el-button
          >
          <el-button v-throttle @click="resetBtn">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-table
        v-loading="loading"
        border
        ref="tenantTable"
        :data="tenantTable"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
      >
        <el-table-column
          prop="code"
          label="租户编码"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="name"
          label="租户名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="tenantStatus" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.tenantStatus | tenantStatusFilters }}
          </template>
        </el-table-column>
        <el-table-column
          prop="tenantStatus"
          label="服务有效期"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.startTime | dayFilters }}
            <span v-if="scope.row.endTime !== null">~</span>
            {{ scope.row.endTime | dayFilters }}
          </template>
        </el-table-column>
        <el-table-column label="操作" show-overflow-tooltip width="200">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              plain
              @click="dialogVisibleBtn('update', scope.row)"
              >编辑</el-button
            ><el-button
              size="mini"
              type="warning"
              plain
              @click="dialogLicenseBtn(scope.row)"
              >授权</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
    <!-- 添加编辑弹窗 -->
    <el-dialog
      :title="dialogVisibleTit"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-form
        :model="dialogVisibleData"
        :rules="dialogVisibleRules"
        ref="dialogVisibleData"
        label-width="100px"
        hide-required-asterisk
        class="demo-ruleForm"
      >
        <el-form-item label="租户编码" prop="code">
          <el-input v-model="dialogVisibleData.code"></el-input>
        </el-form-item>
        <el-form-item label="租户名称" prop="name">
          <el-input v-model="dialogVisibleData.name"></el-input>
        </el-form-item>
        <el-form-item label="系统名称" prop="avatarTitle">
          <el-input v-model="dialogVisibleData.avatarTitle"></el-input>
        </el-form-item>
        <el-form-item label="系统logo" prop="avatarUrl">
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :http-request="uploadImg"
            :show-file-list="false"
          >
            <img
              v-if="dialogVisibleData.avatarUrl"
              :src="this.$imageAddress(dialogVisibleData.avatarUrl)"
              class="avatarImg"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadBtn('dialogVisibleData')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="授权" :visible.sync="dialogLicense" width="488px">
      <el-form
        :model="dialogLicenseData"
        :rules="dialogLicenseRules"
        ref="dialogLicenseData"
        label-width="100px"
        hide-required-asterisk
        class="demo-ruleForm"
      >
        <el-form-item label="租户编码" prop="code">
          <el-input v-model="dialogLicenseData.code" disabled></el-input>
        </el-form-item>
        <el-form-item label="租户名称" prop="name">
          <el-input v-model="dialogLicenseData.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="限制用户数" prop="userLimitCount">
          <el-input
            v-model.number="dialogLicenseData.userLimitCount"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否启用" prop="licenseStatus">
          <el-switch
            v-model="dialogLicenseData.licenseStatus"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="授权有效期" prop="timeList">
          <el-date-picker
            v-model="dialogLicenseData.timeList"
            value-format="timestamp"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogLicense = false">取 消</el-button>
        <el-button
          type="primary"
          @click="updateTenantLicense('dialogLicenseData')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  getTenantApi,
  addTenantApi,
  updateTenantApi,
  updateTenantLicenseApi,
} from '@/api/user/tenantManage.js'
import { fileSingle } from '@/api/fileUpload/fileUpload.js'
export default {
  name: 'roleManage',
  components: {},
  data() {
    return {
      //遮罩层
      loading: false,
      tenantTable: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      conditionForm: {
        query: '',
        tenantStatus: '',
      },
      // 状态列表
      conditionList: {
        tenantStatus: [104, 102, 1, 0],
      },
      dialogVisible: false,
      dialogVisibleType: 'add',
      dialogVisibleTit: '添加租户',
      dialogVisibleData: {
        avatarTitle: '',
        avatarUrl: '',
        code: '',
        name: '',
        tenantId: '',
      },
      dialogVisibleRules: {
        name: [{ required: true, message: '必填项', trigger: 'blur' }],
        code: [{ required: true, message: '必填项', trigger: 'blur' }],
      },
      dialogLicense: false,
      dialogLicenseData: {
        code: '',
        name: '',
        tenantId: '',
      },
      // 授权
      dialogLicenseRules: {
        userLimitCount: [
          { required: true, message: '必填项', trigger: 'blur' },
        ],
        timeList: [{ required: true, message: '必填项', trigger: 'blur' }],
      },
    }
  },
  mounted() {
    this.getTenant()
  },
  methods: {
    // 获取登录用户所在租户
    getTenant(condition) {
      this.loading = true
      getTenantApi(this.page.pageIndex, this.page.pageSize, condition)
        .then((res) => {
          this.loading = false
          console.log('res', res)
          if (res.success) {
            let { data, pageIndex, total } = res
            this.tenantTable = data
            this.page.pageIndex = pageIndex
            this.page.totalSize = total
          }
        })
        .catch((err) => {})
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.changeConditionForm()
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.page.pageIndex = 1
      this.page.pageSize = val
      this.changeConditionForm()
    },
    changeConditionForm() {
      let conditionObj = JSON.parse(JSON.stringify(this.conditionForm))
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      this.getTenant(this.$qs.stringify(conditionObj))
    },
    // 重置
    resetBtn() {
      for (let key in this.conditionForm) {
        this.conditionForm[key] = ''
      }
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    // 上传图片
    uploadImg(file) {
      let fd = new FormData()
      // fd.append('code', 'default')
      fd.append('code', 'user_img')
      fd.append('file', file.file)
      fileSingle(fd)
        .then((res) => {
          console.log('上传图片', res)
          if (res.success) {
            // 上传成功 获取链接替换avatarUrl
            this.dialogVisibleData.avatarUrl = res.data.url
          }
        })
        .catch((err) => {})
    },
    // 添加、编辑租户弹窗
    dialogVisibleBtn(key, val) {
      console.log(key, val)

      switch (key) {
        case 'add':
          this.dialogVisibleTit = '添加租户'
          this.dialogVisibleData = {
            avatarTitle: '',
            avatarUrl: '',
            code: '',
            name: '',
          }
          break
        case 'update':
          this.dialogVisibleTit = '编辑租户'
          let { avatarTitle, avatarUrl, code, name, tenantId } = val
          this.dialogVisibleData = {
            avatarTitle,
            avatarUrl,
            code,
            name,
            tenantId,
          }
          break
      }
      this.dialogVisibleType = key
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.dialogVisibleData.clearValidate()
      })
    },
    // 新增/编辑租户
    uploadBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const apiMap = {
            add: addTenantApi,
            update: updateTenantApi,
          }
          apiMap[this.dialogVisibleType](this.dialogVisibleData)
            .then((res) => {
              if (res.success) {
                this.$message.success('保存成功')
                this.dialogVisible = false

                this.resetBtn()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((err) => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 授权弹框
    dialogLicenseBtn(val) {
      console.log(' val', val)
      this.dialogLicenseData = {
        tenantId: val.tenantId,
        code: val.code,
        name: val.name,
        userLimitCount: val.userLimitCount,
        licenseStatus: !!val.licenseStatus ? 1 : 0,
        timeList:
          val.startTime !== null && val.endTime !== null
            ? [val.startTime, val.endTime]
            : [],
      }
      this.dialogLicense = true
      this.$nextTick(() => {
        this.$refs.dialogLicenseData.clearValidate()
      })
    },
    // 授权
    updateTenantLicense(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let {
            tenantId,
            code,
            name,
            userLimitCount,
            licenseStatus,
            timeList: [startTime, endTime],
          } = this.dialogLicenseData
          let data = {
            tenantId,
            code,
            name,
            userLimitCount,
            licenseStatus,
            startTime,
            endTime,
          }
          updateTenantLicenseApi(data)
            .then((res) => {
              if (res.success) {
                this.$message.success('保存成功')
                this.dialogLicense = false
                this.resetBtn()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((err) => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
  watch: {},
  filters: {
    tenantStatusFilters(key) {
      switch (key) {
        case 104:
          return `租户未被授权`
        case 102:
          return `租户授权过期`
        case 1:
          return `正常使用中`
        case 0:
          return `租户被禁用`
        default:
          return `状态码${key}`
      }
    },
    dayFilters(val) {
      // return val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
      return val ? dayjs(val).format('YYYY-MM-DD') : ''
    },
  },
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #ccc;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #ccc;
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar-uploader img {
  max-width: 100%;
  max-height: 100%;
}
</style>
