import { request } from '@/utils/request.js'

//获取登录用户所在租户
export function getTenantApi (pageIndex, pageSize, data) {
  return request({
    url: `/sys/tenant/tenant/page/${pageIndex}/${pageSize}/list`,
    method: 'post',
    data
  })
}


//添加租户信息 租户名称，租户编码
export function addTenantApi (data) {
  return request({
    url: `/sys/tenant/tenant/add`,
    method: 'post',
    data
  })
}

//修改租户信息
export function updateTenantApi (data) {
  return request({
    url: `/sys/tenant/tenant/update`,
    method: 'post',
    data
  })
}

//修改租户授权
export function updateTenantLicenseApi (data) {
  return request({
    url: `/sys/tenant/license/update`,
    method: 'post',
    data
  })
}